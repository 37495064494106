exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-documents-tsx": () => import("./../../../src/pages/documents.tsx" /* webpackChunkName: "component---src-pages-documents-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-listserv-tsx": () => import("./../../../src/pages/listserv.tsx" /* webpackChunkName: "component---src-pages-listserv-tsx" */),
  "component---src-pages-results-tsx": () => import("./../../../src/pages/results.tsx" /* webpackChunkName: "component---src-pages-results-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-templates-article-template-jsx": () => import("./../../../src/templates/article-template.jsx" /* webpackChunkName: "component---src-templates-article-template-jsx" */),
  "component---src-templates-articles-page-template-jsx": () => import("./../../../src/templates/articles-page-template.jsx" /* webpackChunkName: "component---src-templates-articles-page-template-jsx" */)
}

